import axios from 'axios';
import { SIMPLE_WINE_API_URL } from '../../config';
import { GET_WINES, LOADING, WINE_EDITED, GET_WINE, LOADED } from './type';
import store from '../../store';
import { successNotif, errorNotif, initNotif } from '../../actions/notifications';

const setVuforiaName = (id, name) => {
  if (name !== null) {
    return `M_${id}_${name.toUpperCase().replace(/ /g, '_')}`;
  }
  return null;
};

const filterNotes = (notes) => {
  const notesWithoutOrder = notes.filter(note => note.order === null);
  const notesWithOrder = notes.filter(note => note.order !== null);
  return {
    notesWithoutOrder,
    notesWithOrder
  };
}

export const getWines = () => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING });
      const { data } = await axios.get(`${SIMPLE_WINE_API_URL}/wines`);
      if (!data.success) {
        throw (data);
      }
      dispatch({
        type: GET_WINES,
        data: data.data
      });
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const getCurrentWine = (id) => {
  return async dispatch => {
    try {
      const { data } = await axios.get(`${SIMPLE_WINE_API_URL}/wines/${id}/site`);
      data.data.Notes = filterNotes(data.data.Notes)
      console.log("data :", data.data);
      data.data.vuforiaName = setVuforiaName(id, data.data.name);
      dispatch({
        type: GET_WINE,
        data: data.data
      });
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const addWine = () => {
  return async dispatch => {
    try {
      const { data } = await axios.post(`${SIMPLE_WINE_API_URL}/wines`);
      dispatch({
        type: GET_WINES,
        data: [
          data.data,
          ...store.getState().wineReducer.wines
        ]
      });
      dispatch(successNotif(data.message));
      dispatch(initNotif());
      return data.data;
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const setWineLabel = (id, label) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append('label', label);
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/wines/${id}/label`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: WINE_EDITED,
        data: data.data
      });
      dispatch(successNotif(data.message));
      return data;
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
      return e;
    }
  }
}

export const setWineEtq = (id, etq) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append('etq_reco', etq);
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/wines/${id}/etq`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: WINE_EDITED,
        data: data.data
      });
      dispatch(successNotif(data.message));
      return data;
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
      return e;
    }
  }
}

export const editWine = ({ id, body }) => {
  return async dispatch => {
    try {
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/wines/${id}`, body);
      data.data.Notes = filterNotes(data.data.Notes);
      data.data.vuforiaName = setVuforiaName(id, data.data.name);
      dispatch({
        type: GET_WINE,
        data: data.data
      });
      dispatch(successNotif(data.message));
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const deleteWine = (id) => {
  return async dispatch => {
    try {
      const { data } = await axios.delete(`${SIMPLE_WINE_API_URL}/wines/${id}`);
      if (!data.success) {
        throw (data);
      }
      let wines = store.getState().wineReducer.wines;
      wines = wines.filter(wine => wine.id !== id)
      dispatch({
        type: GET_WINES,
        data: wines
      });
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const addTarget = (wine) => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING });
      const { data } = await axios.post(`${SIMPLE_WINE_API_URL}/wines/${wine.id}/vuforia`, {
        name: wine.vuforiaName,
        width: wine.width,
        image: wine.etq_reco,
      });
      data.data.Notes = filterNotes(data.data.Notes);
      dispatch({ type: LOADED,
        data: data.data });
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch({ type: LOADED });
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const editTarget = (wine) => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING });
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/wines/${wine.id}/vuforia/${wine.targetId}`, {
        name: wine.vuforiaName,
        image: wine.etq_reco,
        width: wine.width
      });
      data.data.Notes = filterNotes(data.data.Notes);
      dispatch({ type: LOADED,data: data.data });
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch({ type: LOADED });
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const deleteTarget = (wine) => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING });
      const { data } = await axios.delete(`${SIMPLE_WINE_API_URL}/wines/${wine.id}/vuforia/${wine.targetId}`);
      dispatch({ type: LOADED });
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch({ type: LOADED });
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}