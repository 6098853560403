const setSimpleWineApiUrl = () => {
  let url;
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      url = 'https://test-api-simple-wine-oaqthzkpda-ew.a.run.app';
      break;
    case 'test':
      url = 'https://test-api-simple-wine-oaqthzkpda-ew.a.run.app';
      break;
    case 'development':
      url = 'https://test-api-simple-wine-oaqthzkpda-ew.a.run.app';
      break;
    default:
      url = 'https://test-api-simple-wine-oaqthzkpda-ew.a.run.app';
      break;
  }
  return url;
}
export const SIMPLE_WINE_API_URL = setSimpleWineApiUrl();

export const SSO_URL = 'https://api-sso-oaqthzkpda-ew.a.run.app';
