import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ItemWine from './ItemWine';
import './Wines.scss';
import Add from '../../assets/images/icons/add.png';
import { getWines, addWine } from '../../actions/wines';
import Search from "../Search/Search";

const ListWines = (props) => {
  const { getWines, loading, wines, addWine, history } = props;

  useEffect(() => {
    getWines();
  }, [getWines]);

  const addSingleWine = async () => {
    const wine = await addWine();
    if (wine !== undefined) {
      history.push(`/wine/${wine.id}`);
    }
  }

  console.log(wines);

  return (
    <div className="listWines">
      <Search type='wine' />
      <h1>Liste des vins</h1>
      <button className="add" onClick={() => addSingleWine()}>
        <img src={Add} alt="add" />
        Ajouter un vin
      </button>
      <div className="list">
        {loading
          ? <div>Chargement</div>
          : wines.map(wine => <ItemWine key={wine.name + wine.id.toString()} data={wine} />)
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ wineReducer }) => {
  return {
    wines: wineReducer.wines,
    loading: wineReducer.loading,
    error: wineReducer.error,
  }
};

export default connect(mapStateToProps, {
  getWines,
  addWine
})(withRouter(ListWines));