import React from 'react';
import { connect } from 'react-redux';
import ItemNote from './ItemNote';
import './Notes.scss';
import { addNote } from '../../actions/notes';
import Dices from '../Dices';
import Parking from '../Parking';
const ListNotes = (props) => {
  const { notesWithoutOrder, notesWithOrder, wine, addNote } = props;
  let totalNotes = null;
  if (notesWithoutOrder && notesWithOrder) {
    totalNotes = notesWithoutOrder.length + notesWithOrder.length;
  } 
  

  const renderNotes = () => (
    <div className="d-flex">
      <div className="list">
      <Parking face={null}  notesWithoutOrder={notesWithoutOrder} />
        {/* {notesWithoutOrder.map(note =>
          <ItemNote
            key={note.id}
            note={note}
          />
        )} */}
      </div>
      <Dices notes={notesWithOrder} />
    </div>
  )

  return (
    <div className="listNotes">
      <div className="row title-row">
        <h1>Liste des notes</h1>
        <button className="add" onClick={() => addNote({ WineId: wine.id })}>
          Ajouter une note
        </button>
      </div>

      {
        totalNotes >= 1
          ? renderNotes()
          : <div>Aucune note</div>
      }
    </div>
  )
}

const mapStateToProps = ({ wineReducer }) => {
  return {
    wine: wineReducer.wine,
    notesWithOrder: wineReducer.wine.Notes.notesWithOrder,
    notesWithoutOrder: wineReducer.wine.Notes.notesWithoutOrder,
  }
};

export default connect(mapStateToProps, {
  addNote
})(ListNotes);