import React from "react";
import { withRouter, Link } from 'react-router-dom';
import './Wines.scss';

const ItemWine = (props) => {
  const { id, etq_reco, name } = props.data;

  return (
    <div className="itemWine">
      <Link className="content" to={`/wine/${id}`}>
        <div className="logo">
          <img src={etq_reco} alt="Etiquette de vin" />
        </div>
        <div className="name">{name}</div>
      </Link>
    </div>
  )
}

export default withRouter(ItemWine);