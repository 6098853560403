import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import './Notes.scss';
import NoteModal from '../Modals/noteModal';
import NoteIcon from '../../assets/images/icons/medal.png';

const ItemNote = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { note, vintage, active, background } = props.note;

  const openModal = () => setModalStatus(true);
  const closeModal = () => setModalStatus(false);

  useEffect(() => {
    if (props.noteReducer.note && props.noteReducer.note.id === props.note.id) {
      setModalStatus(props.noteReducer.openModal);
    }
  }, [props.note.id, props.noteReducer.openModal, props.noteReducer.note]);

  const drag = (e) => {
    e.dataTransfer.setData('Text', JSON.stringify(props.note));
  }
  console.log("note :" ,props.note);
  return (
    <div
      draggable="true"
      onDragStart={e => drag(e)}
      className="itemNote"
    >
      <div className="content" onClick={openModal}>
        <img className="noteIcon" src={background ? background : NoteIcon} alt="Note Icon" />
        <div className="note">{note}<span className="units"> PTS</span></div>
        <div className="vintage">{vintage}</div>
      </div>
      <NoteModal
        isOpen={modalStatus}
        openmodal={() => openModal}
        closeModal={() => closeModal}
        note={props.note}
      />
    </div>
  )
}

const mapStateToProps = ({ noteReducer }) => {
  return {
    noteReducer
  }
};

export default connect(mapStateToProps, null)(ItemNote);